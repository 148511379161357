<template>
    <select-field :value="value"
                  :options="options"
                  label-key="translated_name"
                  class="select-document-type"
                  :loading="loading"
                  :as-key="asKey"
                  :label="label"
                  :name="name"
                  :required="required"
                  :disabled="disabled"
                  :errors="errors"
                  :independent="independent"
                  :placeholder="placeholder"
                  :readonly="readonly"
                  @change="$emit('change', $event)" />
</template>

<script>
import { resourceable } from '@/store/connectors'

import { injectCommonFieldProps, injectSelectFieldProps } from '@/utils/ui'

import SelectField from '@/components/fields/SelectField.vue'

export default {
  components: {
    SelectField
  },
  mixins: [
    resourceable({ on: 'documents', name: 'documentCreatableTypes' }),
    resourceable({ on: 'documents', name: 'documentShowTypes' })
  ],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...injectCommonFieldProps(),
    ...injectSelectFieldProps(),

    value: { type: [Array, Object, String], default: null },

    defaultValue: { type: String, default: null },
    withAll: { type: Boolean,  default: false },
    toCreate: { type: Boolean, default: false }
  },
  computed: {
    options() {
      return [
        this.withAll && { value: null, translated_name: 'Все' }, 
        ...(this.toCreate ? this.documentCreatableTypes : this.documentShowTypes) || []
      ].filter(is)
    },
    loading() {
      return this.documentCreatableTypesLoading || this.documentShowTypesLoading
    }
  },
  mounted() {
    const fetch = this.toCreate ? this.fetchDocumentCreatableTypes : this.fetchDocumentShowTypes

    fetch().then(r => {
      this.defaultValue && this.changeInner(r?.find(x => x.value === this.defaultValue))
    })
  },
  methods: {
    changeInner(x) {
      this.$emit('change', this.asKey ? x.value : x)
    }
  }
}
</script>
