<template>
    <fieldable 
        :label="label"
        :name="name"
        :required="required"
        :errors="errors"
        :no="independent">
        <div class="f-v-center space-x-0.5 w-full">
            <!-- Select project -->
            <el-checkbox v-if="withAtProject"
                         :value="atProject"
                         :disabled="disabled"
                         @change="belongToProject">
                {{ labels.atProject }}
            </el-checkbox>
                                
            <!-- Select place -->
            <el-cascader ref="selector"
                         v-model="valueResult"
                         v-loading="loading"
                         class="w-full without-removable-tags reset-el-cascader-search-input-height"
                         :popper-class="classes.popper"
                         size="mini"
                         :options="projectPlaces"
                         :disabled="disabled || (withAtProject && atProject)"
                         :placeholder="placeholder"
                         :props="{
                             checkStrictly: true,
                             multiple: multiple
                         }"
                         filterable
                         clearable
                         @change="belongToPlace">
                <span slot-scope="{ node, data }"
                      class="f-v-center space-x-0.25">
                    <icon v-if="data.type === 'work_plan'"
                          name="polygon"
                          class="w-1" />
                    <span>
                        {{ node.label }}
                    </span>
                </span>
            </el-cascader>
        </div>
    </fieldable>
</template>

<script>
import { resourceable } from '@/store/connectors'

import { then } from '@/utils/immutable'
import { injectCommonFieldProps } from '@/utils/ui'

import Fieldable from '@/components/fields/Fieldable'

const labels = {
  label: 'Относится к',
  placeholder: 'Выбрать объект',
  atProject: 'Проект или'
}

export default {
  components: {
    Fieldable
  },
  mixins: [
    resourceable({ 
      on: 'projects', 
      name: 'projectPlaces', 
      mounted: ({ self }) => ({ 
        withFloors: self.withFloors,
        withPlans: self.withPlans,
        withWorkPlans: self.withWorkPlans
      }) 
    })
  ],
  model: { prop: 'value', event: 'change' },
  props: {
    ...injectCommonFieldProps(),

    value: { type: [Object, Array], default: undefined },
    multiple: { type: Boolean, default: false },

    withAtProject: { type: Boolean, default: false },
    withFloors: { type: Boolean, default: true },
    withPlans: { type: Boolean, default: false },
    withWorkPlans: { type: Boolean, default: false },

    byWorkLayerType: { type: String, default: null },

    houseOrNestedFloor: { type: Boolean, default: false },
    onlyPlan: { type: Boolean, default: false }
  },
  data() {
    return {
      valueResult: undefined,
      atProject: this.withAtProject,
      valueBeforeAtProject: this.multiple ? [] : {},
      labels
    }
  },
  computed: {
    loading() {
      return this.projectPlacesLoading
    },

    classes() {
      return {
        popper: [
          'place-field',
          this.onlyPlan ? 'only-plan' : ''
        ].join(' ')
      }
    }
  },
  watch: {
    /** TODO@shit because el-selector is broken, 70+ issues **/
    value: {
      handler(x) {
        this.atProject = this.withAtProject && !this.value
        this.valueResult = this.atProject ? undefined : this.fromPositions(x)
      },
      immediate: true
    },

    byWorkLayerType(x) {
      this.clear()

      this.$nextTick(() => this.fetchProjectPlaces({
        withFloors: this.withFloors,
        withPlans: this.withPlans,
        withWorkPlans: !!x,

        byWorkLayerType: x
      }))
    }
  },
  methods: {
    fromPositions(x) {
      const values = array(x || []).map(({ house, floor, plan, work_plan }) => [
        house?.id, 
        floor?.id, 
        plan?.id || work_plan?.id
      ].filter(x => x))

      return this.multiple ? values : values[0]
    },

    toPositions() {
      const nodes = this.$refs.selector.getCheckedNodes()

      let positions = nodes
        .map(({ pathNodes }) => pathNodes
          .map(({ data }) => data)
          .reduce((r, x) => ({
            ...r,
            [x.type]: {
              id: x.id,
              label: x.label,
              type: x.type,
              ...then(x.type === 'work_plan', () => ({ layers: x.layers }))
            }
          }), {}))

      positions = this.houseOrNestedFloor 
        ? positions.reduce((r, x) => [...r, ...r.some(y => y.house?.id === x.house?.id) ? [] : [x]], []) 
        : positions

      return positions;
    },

    belongToProject(x) {
      this.atProject = x
      this.atProject && (this.valueBeforeAtProject = this.value)
      this.$emit('change', x ? undefined : this.valueBeforeAtProject)
    },

    belongToPlace() {
      const positions = this.toPositions()

      this.$emit('change', this.atProject ? undefined : (this.multiple ? positions : (positions[0] || {}))) 
    },

    clear() {
      this.$emit('change', this.atProject ? undefined : (this.multiple ? [] : {}))
    }
  }
}
</script>

<style lang="scss">
  .place-field.only-plan {
    .el-cascader-menu {
      &:nth-child(1), &:nth-child(2) {
        .el-checkbox {
          display: none;
        }
      }
    }
  }
</style>
