import Polygon from 'ol/geom/Polygon'
import Feature from 'ol/Feature'

import { FEATURE_TYPES } from '@/utils/plan'
import { difference, equalityById, intersectionWithZip } from '@/utils/immutable'
import { createErrorOverlay, removeOverlay } from '@/utils/ui'

import { getPolygons, getPolygonsByIds, states, toPolygon } from '@/models/tasks'
import { isAcceptance, isWorkPolygon } from '@/models/shapes'
import { getWorkPolygonLastAcceptanceWorkState, isWorkLayerConfirmed, isWorkPolygonHasLastAcceptanceWorkResult } from '@/models/plans'

import jack from '@/utils/graphics/OpenLayersJack'

export default ({ map, source, planId: x, layerId: y }) => {
  let planId = x
  let layerId = y
  let task
  let taskPolygons = []
  let taskPolygonsByIds = {}
  let width, height
  let offsetWidth, offsetHeight

  let errorOverlays = []

  const clearErrors = () => {
    errorOverlays.map(removeOverlay)
    errorOverlays = []
  }

  const serialize = () => {
    clearErrors()

    const features = source
      .getFeatures()
      .filter(isAcceptance)

    const polygonsForPatch = jack
      .by({ map, source})
      .filter((feature) => 
        isWorkPolygon(feature) && 
        !!feature.get('polygon')?._updated_for_new_task
      )

    const polygonsForAttach = polygonsForPatch
      .map(feature => toPolygon({
        id: feature.get('id'),
        type: feature.get('acceptanceType'),
        vertices: feature.getGeometry().getCoordinates()[0]
      }))

    const polygonsForRestore = features
      .filter(feature => !feature.get('id'))

    const polygonsForCreate = polygonsForRestore
      .map(feature => toPolygon({
        id: null,
        type: feature.get('acceptanceType'),
        vertices: feature.getGeometry().getCoordinates()[0].map(([x, y]) => [x - offsetWidth, y - offsetHeight])
      }))

    const polygonsFromEditorByTask = features
      .filter(feature => taskPolygonsByIds[feature.get('id')])
      .map(feature => toPolygon({
        id: feature.get('id') || null,
        type: feature.get('acceptanceType'),
        vertices: feature.getGeometry().getCoordinates()[0]
      }))

    const polygonsForRemove = difference(taskPolygons, polygonsFromEditorByTask, equalityById)

    return {
      polygonsForPatch,
      polygonsForRestore,
      polygonsForAttach,

      polygonsForCreate,
      polygonsForRemove
    }
  }

  return {
    applyBackground({ width: w, height: h, offsetWidth: ow, offsetHeight: oh }) {
      jack
        .by({ map, source })
        .toFeatures()
        .forEach(feature => jack.by({ source, feature }).move(ow - offsetWidth, oh - offsetHeight))

      width = w
      height = h
      offsetWidth = ow
      offsetHeight = oh
    },

    applyTask(x) {
      task = x

      taskPolygons = getPolygons(task, { planId, layerId })
      taskPolygonsByIds = getPolygonsByIds(task, { planId, layerId })
    },

    applyPolygons(x, isSnapshot = false) {
      const features = isSnapshot ? x : x.map(({ id, type, vertices, task, createdAt, acceptable }) => new Feature({
        geometry: new Polygon([vertices.map(([x, y]) => [x + offsetWidth, y + offsetHeight])]),
        id,
        acceptanceType: type,
        type: ({
          'finish': acceptable ? FEATURE_TYPES.FINAL_ACCEPTANCE : FEATURE_TYPES.FINAL_ACCEPTANCE_OLD,
          'temp': acceptable ? FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE : FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE_OLD
        })[type],
        createdAt,
        task
      }))

      jack
        .by({ map, source })
        .filter(isAcceptance)
        .forEach(feature => jack.by({ source, feature }).remove())

      features.map(
        feature => jack.by({ source, feature })
          .draw()
      )
    },

    applyWorkPolygons(x, { planId: y, layer, snapshot, comparable, acceptance, acceptable, contractable } = {}) {
      const planChanged = planId !== y && !!(planId = y)

      x = snapshot?.polygonsForPatch
        ? x.map(p => ({ 
          ...p, 
          ...(!p.hasOwnProperty('_updated_for_new_task') && 
            snapshot.polygonsForPatch.find(i => i.get('id') === p.id)?.get('polygon') || {})
        }))
        : x

      const exists = jack
        .by({ map, source })
        .filter(isWorkPolygon)

      const creatable = planChanged 
        ? x 
        : difference(x, exists, (a, b) => a.id === b.get('id'))

      const updatable = planChanged 
        ? [] 
        : intersectionWithZip(x, exists, (a, b) => a._updated && a.id === b.get('id') && a._updated !== b.get('polygon')._updated)

      const removable = planChanged 
        ? exists 
        : difference(exists, x, (a, b) => a.get('id') === b.id)

      const toFeatureType = polygon => 
        // polygons of acceptance (if current user is allowed to acceptable)
        (acceptable && ({
          'finished_accepted': FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_CONFIRMED,
          'finished_not_accepted': FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_REJECTED,
          'not_processed': FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_DEFAULT,
          [undefined]: FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_NONE
        })[polygon.acceptance_result]) ||
        // polygons of acceptance, but not allowed acceptance for user (contractor)
        ((acceptance || (contractable && polygon['_updated_for_new_task'] !== undefined)) && ({
          'finished_accepted': FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_DEFAULT,
          'finished_not_accepted': FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_DEFAULT,
          'not_processed': FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_DEFAULT,
          [undefined]: FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_NONE
        })[polygon.acceptance_result]) ||
        // by last acceptance work result, only for contractor (API) and contractable mode
        (contractable && isWorkPolygonHasLastAcceptanceWorkResult(polygon) && (({
          [getWorkPolygonLastAcceptanceWorkState(polygon) === states.FINISHED]: FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_CONFIRMED,
          [getWorkPolygonLastAcceptanceWorkState(polygon) === states.FINISHED_NOT_ACCEPTED]: FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_REJECTED,
          [getWorkPolygonLastAcceptanceWorkState(polygon) === null]: FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_NONE
        })[true] || FEATURE_TYPES.WORK_POLYGON_ACCEPTANCE_NONE)) ||
        // polygons of confirmed layer (not comparable)
        ((!comparable && isWorkLayerConfirmed(layer) && polygon.data?.work_status) && ({
          'created': FEATURE_TYPES.WORK_POLYGON_CREATED,
          'at_work': FEATURE_TYPES.WORK_POLYGON_AT_WORK,
          'completed': FEATURE_TYPES.WORK_POLYGON_COMPLETED,
          'completed_partially': FEATURE_TYPES.WORK_POLYGON_COMPLETED_PARTIALLY,
          'with_problem': FEATURE_TYPES.WORK_POLYGON_WITH_PROBLEM,
          'with_warning': FEATURE_TYPES.WORK_POLYGON_WITH_WARNING
        })[polygon.data?.work_status]) ||
        // polygons of layer reform or comparison
        (polygon.status && ({
          'new': FEATURE_TYPES.WORK_POLYGON_NEW,
          'changed': FEATURE_TYPES.WORK_POLYGON_CHANGED,
          'unchanged': FEATURE_TYPES.WORK_POLYGON_UNCHANGED
        })[polygon.status])

      creatable
        .map(polygon => ({
          ...polygon,
          polygon
        }))
        .map(({ id, marks, polygon }) => new Feature({
          id,
          geometry: new Polygon([marks.map(([x, y]) => [x * width - offsetWidth, (1 - y) * height - offsetHeight])]),
          type: toFeatureType(polygon),
          polygon
        }))
        .forEach(feature => jack.by({ source, feature }).draw())

      updatable
        .forEach(([polygon, feature]) => {
          feature.set('polygon', polygon)
          feature.set('type', toFeatureType(polygon))
        })

      removable
        .forEach(feature => jack.by({ source, feature }).remove())

      //console.log(creatable, updatable, removable)

      // const features = jack.by({ source }).toFeatures()
      // console.log({ id, planChanged, x, exists, removable, creatable, features })
      // Слав, я закомментил

      return {
        shouldDeselect: !!removable.length,
        shouldReselect: !!updatable.length
      }
    },

    getTaskPolygonsById() {
      return taskPolygonsByIds
    },

    getPolygonsCount() {
      return source.getFeatures().filter(isAcceptance).length
    },

    applyErrors(x) {
      clearErrors()

      errorOverlays = Object.entries(x)
        // find features
        .map(([k, v]) => [jack.by({ source }).find(x => [x.get('id')].includes(k)), v])
        // filter nullable features
        .filter(([f]) => f)
        // create tooltips
        .map(([f, v]) => createErrorOverlay(map, f, v))
    },

    clearErrors,
    serialize,

    save({ doSave }) {
      return compose(
        doSave,
        serialize
      )()
    }
  }
}
