import { symmetricalDifference } from '@/utils/immutable'
import { actionable, loadable, pagenable, resourceable } from '@/store/mixins'
import { formatForServer, parse } from '@/utils/date'
import { getType } from 'mime'
import { treeTypes } from '@/models/documents'
import { filterFoldersByGroupType } from '@/utils/folders'
import { startOfDay, endOfDay } from 'date-fns'

export default {
  namespaced: true,
  mixins: [
    loadable({ action: 'compare' }),
    loadable({ action: 'download' }),

    pagenable({
      name: 'documents',
      from: ({ api, getters, page, payload: { from, to, forAcceptance } = {} }) => api.documents.getProjectDocuments({
        project: getters['project/project'],
        params: {
          ...getters['documents/searchQuery'] && { find: getters['documents/searchQuery'] },

          filter: {
            ...from && { created_at_from: formatForServer(from) },
            ...to && { created_at_to: formatForServer(to) },
            ...forAcceptance && { available_to_acceptance_work_task: 1 }
          },

          page: {
            number: page
          }
        }
      }).then(x => x?.data || {})
    }),

    resourceable({
      name: 'documentsCountByType',
      from: ({ api, getters }) => api.documents.getDocumentsCountByType({
        project: getters['project/project']
      }).then(r => r.data.data || {})
    }),

    resourceable({
      name: 'documentCreatableTypes',
      from: ({ api, getters }) => api.documents.getDocumentCreatableTypes({
        project: getters['project/project']
      })
    }),

    resourceable({
      name: 'documentShowTypes',
      from: ({ api, getters }) => api.documents.getDocumentShowTypes({
        project: getters['project/project']
      })
    }),

    actionable({
      name: 'reprocess',
      loadable: true,
      at: ({ api, getters }, { document }) => api.documents.reprocess({
        project: getters['project/project'],
        document
      })
    }),


    pagenable({
      name: 'documentsForTree',
      from: ({ api, getters }, payload) => {
        const { type, group, folderId, page, from, to, sort } = payload
        const { 
          withAuthor, 
          withAvatar, 
          withOrganization, 
          withApprovalTask, 
          withMainDocument, 
          withAccompanyingDocuments, 
          withBim,

          withStructurables,
          withLastThreeDownloadedUsers
        } = payload

        const params = {
          page: { number: page },
          filter: {
            folder_type: 'local',
            ...type && { document_type: type },
            ...group && { document_group_type: group },
            ...folderId && { folder_id: folderId },
            ...from && { createdAtFrom: formatForServer(startOfDay(parse(from, { withTime: false }))) },
            ...to && { createdAtTo: formatForServer(endOfDay(parse(to, { withTime: false }))) }
          }, 
          ...sort && { sort },
          append: [
            withStructurables && 'structurables',
            withLastThreeDownloadedUsers && 'lastThreeDownloadedUsers'
          ].filter(is),
          include: [
            withAuthor && 'author',
            withAvatar && 'author.avatar',
            withOrganization && 'author.organization',
            withApprovalTask && !withMainDocument && !withAccompanyingDocuments && 'approvalTask',
            withMainDocument && 'approvalTask.mainDocument',
            withAccompanyingDocuments && 'approvalTask.accompanyingDocuments',
            withBim && 'bim'
          ].filter(is)
        }

        return api.documentsV3.getProjectDocumentIndex(getters['project/projectId'], { params })
      }
    }),

    actionable({
      name: 'getFolderById',
      at: ({ api, getters }, documentId) => 
        api.documentsV3.getFolderById(getters['project/projectId'], documentId)
    })
  ],
  state: {
    selectedDocuments: [],
    renderedPagesByDocument: {},
    folderStructure: [],
    currentFolder: null,
    folderTreeLoading: false,

    searchQuery: null,

    comparison: {
      left: {
        document: null,
        page: null,
        thumb: null,
        preview: null
      },

      right: {
        document: null,
        page: null,
        thumb: null,
        preview: null
      },

      versions: [],
      result: null
    }
  },
  getters: {
    selectedDocuments: state => state.selectedDocuments,
    selectedDocument: state => state.selectedDocuments[0],
    comparison: state => state.comparison,
    getFolderTree: state => state.folderStructure,
    renderedPagesByDocument: state => state.renderedPagesByDocument,
    currentFolder: state => state.currentFolder,
    folderTreeLoading: state => state.folderTreeLoading,
    
    searchQuery: state => state.searchQuery
  },
  mutations: {
    EDIT_LOADING_FOLDER_TREE: (state, payload) => state.folderTreeLoading = payload,
    TOGGLE_DOCUMENT: (state, document) => symmetricalDifference(state.selectedDocuments, [document], (a, b) => a.id === b.id),
    SELECT_DOCUMENT: (state, document) => state.selectedDocuments = [document],
    SET_CURRENT_FOLDER: (state, folder) => state.currentFolder = folder,
    DOCUMENT_MOVE_TO_FOLDER:  (state, { to_folder_id, project_document_id }) => {
      console.log('DOCUMENT_MOVE_TO_FOLDER', to_folder_id)
    },

    SET_SEARCH_QUERY: (state, query) => state.searchQuery = query,

    ADD_RENDERED_PAGE: (state, { document, page, result }) => {
      const id = document.id

      state.renderedPagesByDocument = {
        ...state.renderedPagesByDocument,
        [document.id]: {
          ...state.renderedPagesByDocument[id],
          [page]: result
        }
      }

      const { left, right } = state.comparison

      left.document.id === id && left.page === page && (left.thumb = result['image']['storage_thumb_url'])
      left.document.id === id && left.page === page && (left.preview = result['image']['storage_url'])
      right.document.id === id && right.page === page && (right.thumb = result['image']['storage_thumb_url'])
      right.document.id === id && right.page === page && (right.preview = result['image']['storage_url'])
    },

    PREPARE_TO_COMPARE: (state, { document, as, page, versions }) => {
      state.comparison[as].document = document
      state.comparison[as].page = page
      state.comparison[as].pages = Array.from({ length: document['page_count'] }, (_, i) => i + 1)
      state.comparison[as].thumb = state.renderedPagesByDocument[document.id]?.[page]?.['image']['storage_thumb_url']
      state.comparison[as].preview = state.renderedPagesByDocument[document.id]?.[page]?.['image']['storage_url']

      state.comparison.versions = versions || state.comparison.versions
    },

    SET_COMPARISON_RESULT: (state, result) => state.comparison.result = result,

    RESET_COMPARISON: state => {
      const { left, right } = state.comparison

      Object.keys(left).forEach(key => left[key] = null)
      Object.keys(right).forEach(key => right[key] = null)

      state.comparison.versions = []
      state.comparison.result = null
    },
    EDIT_FOLDER_STRUCTURE: (state,  { data, type}) => {
      state.folderStructure[type] = data
    }

  },
  actions: {
    selectDocument: ({ commit }, document) => commit('SELECT_DOCUMENT', document),
    renderDocumentPage: async function({ commit, rootGetters: getters }, { document, page }) {
      const project = getters['project/project']
      const result = await this.$api.documents.renderDocumentPage({ project, document, page })
      commit('ADD_RENDERED_PAGE', { document, page, result })
    },
    loadFolderStructure: async function({ commit }, { project, type, treeType = treeTypes.TREE, documentType, extensions, updateState = true }) {
      commit('EDIT_LOADING_FOLDER_TREE', true)

      const { withDocumentsCount, withDocumentGroupType } = extensions || {}
      const payload = {
        params: {
          filter: {
            type: 'local',
            ...documentType && 
              (treeType !== treeTypes.TREE_BY_APPROVAL 
                ? { documents_count_by_type: documentType }
                : { document_type: documentType })
          },
          append: [ withDocumentsCount && 'documents_count', withDocumentGroupType && 'document_group_type'].filter(is)
        }
      }

      const isDefaultTree = treeType === treeTypes.TREE
      const fetch = isDefaultTree ? 
        this.$api.documents.getFolderTree : 
        this.$api.documents.getFolderTreeByApproval

      return fetch(project, payload)
        .then((res) => {
          const data = isDefaultTree ? 
            res?.data.data :
            res?.data.data.reduce((arr, x) => ([
              ...arr,
              {
                id: x.type.value,
                name: x.type.value_translated,
                type: x.type.value,
                documents_count: x.documents_count,
                children: filterFoldersByGroupType(x.folders, x.type.value)
              }
            ]), []) || []
          
          if (updateState) commit('EDIT_FOLDER_STRUCTURE', { data, type })
          commit('EDIT_LOADING_FOLDER_TREE', false)

          return data
        })
    },

    createNewFolder: async function({ dispatch }, payload) {
      await this.$api.documents.createNewFolder(payload)
    },
    renameFolder: async function({ dispatch }, payload) {
      await this.$api.documents.renameFolder(payload)
    },


    moveFolder: async function({ commit, dispatch }, payload) {
      await this.$api.documents.moveFolder(payload)
    },
    setCurrentFolder: ({ commit }, payload) => {
      commit('SET_CURRENT_FOLDER', payload)
    },
    documentMoveToFolder: async function({ commit }, payload)  {
      return await this.$api.documents.moveDocument(payload)
    },
    rmFolder: async function({ dispatch }, payload) {
      return await this.$api.documents.rmFolder(payload)
    },

    setSearchQuery({ commit }, query) {
      commit('SET_SEARCH_QUERY', query)
    },

    prepareToCompare: ({ commit, dispatch, getters }, { document: is, as, page = 1, versions }) => {
      const document = is || getters['comparison'][as]['document']
      commit('PREPARE_TO_COMPARE', { document, as, page, versions })
      dispatch('renderDocumentPage', { document, page })
    },

    compare: async function({ commit, rootGetters: getters }) {
      const project = getters['project/project']
      const { left, right } = getters['documents/comparison']

      const { url } = await this.$api.documents.compare({
        project,
        documents: [left.document, right.document],
        pages: [left.page, right.page]
      })

      commit('SET_COMPARISON_RESULT', url)
    },

    resetComparison: ({ commit }) => commit('RESET_COMPARISON'),

    download: async function({ rootGetters: getters }, { document }) {
      const project = getters['project/project']

      const type = getType(document.original_file_name)

      const { data } = await this.$api.projectsV2.downloadProjectDocument(project.id, document.id)

      const file = new Blob([data], { type })
      const url = URL.createObjectURL(file)

      return url
    },

    downloadAsFile: async function({ rootGetters: getters }, { document }) {
      const project = getters['project/project']

      const type = getType(document.original_file_name)

      const { data } = await this.$api.projectsV2.downloadProjectDocument(project.id, document.id)

      const blob = new Blob([data], { type })

      const file = new File([blob], document.original_file_name, { type })

      return file 
    },

    toAttachment: async function({ dispatch }, { document }) {
      return {
        name: document.original_file_name,
        type: getType(document.original_file_name),
        open: async () => window.open(await dispatch('download', { document })),

        document
      }
    }
  }
}
