<template>
    <div class="tree">
        <div :class="[!folderTree[bim ? 'bim' : 'local'] ? 'folder-title_disabled' : '']"
             v-if="needTitle" 
             class="folder-title mt-4"
             @click="handleNodeClick(bim ? 'bim' : 'local')">
            <div class="el-tree el-tree-title"
                 :class="[currentFolder === (bim ? 'bim' : 'local') ? 'el-tree-title_active' : '']">
                <div>
                    <i v-if="loading || folderTreeLoading"
                       class="el-icon-loading" />
                    <folder-icon v-else
                                 :blue="currentFolder === (bim ? 'bim' : 'local')"
                                 width="16" /> {{ bim ? 'BIM360' : 'Документы' }} ({{ projectName }})
                </div>
                <div v-if="!bim"
                     class="el-tree__btms">
                    <el-dropdown
                        trigger="click"
                        @click.native.stop>
                        <el-button type="text"
                                   icon="el-icon-more" />
                        <el-dropdown-menu
                            slot="dropdown">
                            <el-dropdown-item command="handleShowInfo"
                                              @click.native.stop="handleCreateFolder({})">
                                Новая папка
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <el-button v-if="!isTreeByApproval"
                   class="tree__button__new"
                   type="text"
                   icon="el-icon-plus"
                   @click.native.stop="handleCreateFolder({})">
            Новая папка
        </el-button>
        <el-tree ref="folderTree"
                 v-loading="loading && folderTree.length"
                 :data="folderTree.local"
                 empty-text="Нет папок"
                 :props="treeProps"
                 node-key="id"
                 class="el-tree"
                 :expand-on-click-node="false"
                 :allow-drag="allowDrag"
                 :draggable="!bim"
                 :default-expanded-keys="activeNode && [activeNode]"
                 @node-click="handleNodeClick"
                 @node-drop="handleDrop">
            <div slot-scope="{ node, data }"
                 class="el-tree__item"
                 @dblclick.stop="handleTreeClick">
                <div class="el-tree__title folder-icon">
                    <i v-if="loading || folderTreeLoading"
                       class="el-icon-loading"
                       style="font-size: 16px;" />
                    <i v-else-if="data.add"
                       style="font-size: 16px; color: #00c171;"
                       class="el-icon-folder-add" />
                    <i v-else-if="node.data.type === 'removed'"
                       style="font-size: 16px; color: #C0C4CC" 
                       class="el-icon-delete-solid" />
                    <folder-icon v-else
                                 style="min-width: 16px;"
                                 :class="['folder-icon', node.data.type]"
                                 :yellow="true" />
                    <el-skeleton v-if="loading || folderTreeLoading"
                                 style="width: 180px"
                                 variant="text"
                                 animated />
                    <div v-else
                         class="tree-title"
                         :class="[node.data && activeNode && node.data.id === activeNode ? 'tree-title_active' : '']"
                         :title="data.name.length > 25 ? `${data.name} (${data.documents_count})` : ''">
                        {{ data.name }} 
                        <span class="tree-title__count">
                            {{ data.documents_count }}
                        </span>
                    </div>
                </div>
                <div v-if="!data.add && !bim && !isTreeByApproval"
                     class="el-tree__btms">
                    <i class="el-icon-delete-solid"
                       style="color: #F56C6C;"
                       @click.stop="handleDeleteFolder(node)" />
                    <el-dropdown
                        trigger="click"
                        @click.native.stop>
                        <el-button type="text"
                                   icon="el-icon-more" />
                        <el-dropdown-menu
                            slot="dropdown">
                            <el-dropdown-item command="handleShowInfo"
                                              @click.native.stop="handleCreateFolder(node)">
                                Новая папка
                            </el-dropdown-item>
                            <el-dropdown-item command="handleUpdate"
                                              @click.native.stop="handleRenameFolder(node)">
                                Переименовать
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </el-tree>
    </div>
</template>

<script>
import FolderIcon from '@/components/icons/Folder';
import { treeGroupTypes, treeTypes } from '@/models/documents';
import { actionable } from '@/store/connectors';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    FolderIcon
  },
  mixins: [
    actionable({ on: 'documents', name: 'getFolderById' })
  ],
  props: {
    bim: { type: Boolean, default: false },
    move: { type: Boolean, default: false },
    type: { type: String, default: null },
    needTitle: { type: Boolean, default: true, require: false },
    treeType: { type: String, default: treeTypes.TREE_BY_APPROVAL, require: false }
  },
  data() {
    return {
      loading: false,
      activeNode: null,
      dragFolder: null,
      treeProps: {
        children: 'children',
        label: 'name'
      },
      activeGroup: treeGroupTypes.APPROVED
    }
  },
  computed: {
    ...mapGetters({
      projectName: 'project/projectName',
      folderTree: 'documents/getFolderTree',
      folderTreeLoading: 'documents/folderTreeLoading',
      currentFolder: 'documents/currentFolder'
    }),

    projectId() {
      return this.$route.params.projectId
    },
    
    isTreeByApproval() {
      return this.treeType === treeTypes.TREE_BY_APPROVAL
    }
  },
  watch: {
    type() {
      this.activeNode = this.activeGroup
      this.getStructure()
    },

    treeType() {
      this.getStructure()
    }
  },
  mounted() {
    this.getStructure()
  },
  methods: {
    ...mapActions('documents', ['moveFolder', 'setCurrentFolder', 'loadFolderStructure', 'createNewFolder', 'renameFolder', 'rmFolder', 'preparedDocumentIndex']),
    ...mapActions('project', ['getProjectDocumentIndex']),

    getStructure () {
      this.loading = true

      return this.loadFolderStructure({
        project: this.projectId,
        type: 'local',
        treeType: this.treeType,
        documentType: this.type,
        extensions: { withDocumentsCount: true, withDocumentGroupType: this.isTreeByApproval }
      })
        .then((data) => {
          const { folder } = this.$route.query
          
          if (this.isTreeByApproval) {
            if (folder) {
              const isGroup = treeGroupTypes.hasOwnProperty(folder.toUpperCase())
              
              if (isGroup) {
                const node = data.find(x => x.id === folder)

                this.setCurrentFolder(node)
                this.activeNode = node.id
                this.activeGroup = node.id
              } else {
                this.getFolderById(folder.slice(folder.indexOf('-') + 1))
                  .then((node) => {
                    this.setCurrentFolder(node)
                    this.activeNode = folder
                    this.activeGroup = folder.slice(0, folder.indexOf('-'))
                  })
              }
            } else {
              this.setCurrentFolder(data[0])
              this.activeNode = folder || data[0].id
            }
          } else {
            if (folder) {
              this.getFolderById(folder)
                .then((node) => {
                  this.setCurrentFolder(node)
                  this.activeNode = node.id
                })
            } else this.setCurrentFolder()
          } 
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'Не удалось обновить список папок'
          })
        })
        .finally(() => {
          this.loading = false
        })

    },
    handleRenameFolder (treeEl) {
      this.$prompt('Введите новое название', 'Переименовать', {
        confirmButtonText: 'Отправить',
        cancelButtonText: 'Отмена',
        closeOnHashChange: false,
        loading: false,
        distinguishCancelAndClose: true,
        reset: function ()  {
          this.confirmButtonLoading = false
          this.confirmButtonText = 'Отправить'
          this.loading = false
          return this.close()
        },
        beforeClose: (value, node) => {
          if(value === 'cancel' || value === 'close') return node.reset()
          if(node.loading) return

          node.loading = true
          node.confirmButtonLoading = true
          node.confirmButtonText = 'Отправка...'

          this.renameFolder({
            project: this.projectId,
            folder: treeEl?.data?.id || null,
            name: node.inputValue
          }).then(() => {
            this.getStructure()
            this.$message({
              message: 'Папка переименована',
              type: 'success'
            })
          }).catch((e) => {
            console.log(e)
            this.$message({
              type: 'error',
              message: 'Не удалось создать папку'
            })
          }).finally(() => node.reset())

        },
        callback: () => {
        }
      })
    },
    handleDeleteFolder(folder) {
      this.$confirm(
        `Удалить папку: ${folder.data?.name}?`,
        'Удаление папки',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          customClass: 'document-message-box'
        }).then(() => {
        this.loading = true
        this.rmFolder({
          folder: folder?.data?.id,
          project: this.projectId
        }).then(() => {
          this.getStructure()
          this.$message({
            message: 'Удачно!',
            type: 'success'
          })
        }).catch((e) => {
          console.log(e)
        })
      })
    },
    handleCreateFolder (treeEl) {
      this.$prompt('Введите название новой папки', 'Новая папка', {
        confirmButtonText: 'Создать',
        cancelButtonText: 'Отмена',
        closeOnHashChange: false,
        loading: false,
        distinguishCancelAndClose: true,
        reset: function ()  {
          this.confirmButtonLoading = false
          this.confirmButtonText = 'Создать'
          this.loading = false
          return this.close()
        },
        beforeClose: (value, node) => {
          if(value === 'cancel' || value === 'close') return node.reset()
          if(node.loading) return

          node.loading = true
          node.confirmButtonLoading = true
          node.confirmButtonText = 'Создание...'

          this.createNewFolder({
            project: this.projectId,
            parent_id: treeEl?.data?.id || null,
            name: node.inputValue
          }).then(() => {
            this.getStructure()
            this.$message({
              message: 'Папка создана',
              type: 'success'
            })
          }).catch((e) => {
            console.log(e)
            this.$message({
              type: 'error',
              message: 'Не удалось создать папку'
            })
          }).finally(() => node.reset())

        },
        callback: () => {
        }
      })
    },
    async handleMoveFolder(from, to) {
      // this.loading = true
      this.$confirm(
        `Переместить папку: «${from?.name}» в «${to?.name}»?`,
        'Переместить папку',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Переместить',
          cancelButtonText: 'Отмена',
          customClass: 'document-message-box'
        }).then(() => {
        this.loading = true
        this.moveFolder({
          'folder_id': from?.id,
          'to_folder_id': to?.id,
          project: this.projectId
        }).then(() => {
          this.getStructure().then(() => this.loading = false)
          this.$message({
            message: 'Удачно!',
            type: 'success'
          })
        }).catch(() => {
        }).finally(() => {

        })
      }).catch(() => {
        this.getStructure()
      }).finally(() => {
        this.loading = true
      })
    },
    handleTreeClick () {

    },
    async handleNodeClick(node, el) {
      if(!node) return
      if(node === 'bim') {
        let firstFolder = this.folderTree['bim'][0]
        return this.handleNodeClick(firstFolder)
      }

      if(this.move) return this.documentsMoveToFolder(node, el)
      if(node.add) return this.handleCreateFolder(node, el)

      this.setCurrentFolder(node)
      this.activeNode = node.id
      
      const selectedGroup = this.isTreeByApproval && node.id.slice(0, node.id.indexOf('-'))
      if (selectedGroup) this.activeGroup = selectedGroup

      this.$router.push({
        name: 'project.documents',
        query: {
          ...this.$route.query,
          folder: node?.id,
          page: node?.id && 1 || undefined
        },
        params: {

        }
      })

    },
    documentsMoveToFolder (folder) {
      this.$confirm(
        `Переместить документ в папку: ${folder?.name || 'Документы (Корень)'}?`,
        'Переместить документ?',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Переместить',
          cancelButtonText: 'Отмена',
          customClass: 'document-message-box'
        }).then(() => {
        this.loading = true
        this.$emit('moveToFolder', folder?.id)
      })
    },
    handleDrop(draggingNode, node) {
      this.handleMoveFolder(draggingNode?.data, node?.data)
    },
    allowDrag(draggingNode) {
      return !draggingNode.data.add
    },
    handleClickActions(draggingNode, dropNode, type) {
      console.log(draggingNode)
      console.log(dropNode)
      console.log(type)
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.navigation {
  position: fixed;
  width: 300px;
  margin: 0;
  max-height: calc(100% - 60px);
  overflow-y: auto;
}
.folder-title {
  max-width: calc(100% - 34px);
  padding: 10px 0 10px 17px;
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;

  font-size: 12px;
  color: #606266;

  &_disabled {
    .el-tree {
      color: #d5d5d5;
    }
  }
}

.tree {
  &__button {
    &__new {
      color: #606266;
      margin-left: 12px;
    }
  }
}


.el-tree {
  background: transparent;
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 9px;

    width: 100%;
    max-width: calc(100% - 64px);

    position: relative;
  }

  .tree-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &_active {
      color: #409EFF;
    }

    &__count {
      padding: 1px 6px;
      border-radius: 4px;
      background-color: #F4F4F5;
      color: #909399;
    }
  }

  &-title {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &_active {
      color: #409EFF;
    }

    &:hover {
      .el-tree__btms {
        opacity: 1;
      }
    }
  }


  &__title {
    display: flex;
    align-items: center;
    gap: 9px;
    width: 100%;

    font-size: 12px;
    color: #606266;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ::v-deep {
      .el-skeleton__paragraph {
        margin-top: 0;
      }
    }
  }
  ::v-deep {
    .el-tree__empty-block {
      min-height: 20px;
      span {
        font-size: 12px;
        color: #d5d5d5;
      }
    }
  }

  &__btms {
    position: absolute;
    background: #f5f7fa;
    right: 0;

    display: flex;
    justify-content: space-between;
    gap: 12px;
    opacity: 0;
    padding: 0 0 0 6px;

    transition: all ease 0.2s;

    align-items: center;
    font-size: 14px;

    -webkit-box-shadow: 0 0 24px 10px rgba(245, 247, 250, 1);
    -moz-box-shadow: 0 0 24px 10px rgba(245, 247, 250, 1);
    box-shadow: 0 0 24px 10px rgba(245, 247, 250, 1);

    i {
      &:hover {
        opacity: .5;
      }
    }
  }

  ::v-deep {
    .el-tree-node {

      &__expand-icon {
        margin: 0 10px;
      }
      &__content {
        height: 32px;

        &:hover {
          .el-tree__btms {
            opacity: 1;
          }
        }
      }

    }
  }
}

</style>
