<template>
    <div class="content">
        <transition name="slide-fade"
                    mode="out-in">
            <defect-classes :defect-classes="defectClasses"
                            :defect-classes-data="buildingStandardTree"
                            @node:click="handleProtocolFilterClick"
                            @close-defect-classes="closeDefectClasses" />
        </transition>

        <transition name="slide-fade"
                    mode="out-in">
            <premises-classes v-if="premisesClassesVisibility"
                              @click-premises-class="onClickPremisesClass"
                              @cancel-premises-class="onCancelPremisesClass" />
        </transition>

        <!-- Comments -->
        <comments-list v-if="showComments && hasComments"
                       :comments="planComments"
                       @on-comment-edit="handleEditPlanComment"
                       @on-comment-delete="onCommentDelete" />

        <!-- Container --> 
        <div v-loading="commonLoading"
             :class="classes.container">
            <!-- Mapppppaaaamaaappp floor mapppppaaaamaaappp -->
            <floor-map 
                v-if="shouldDisplayViewer && !shouldDisplayComparison"
                key="common"
                ref="viewer" 
                :project-id="projectId"
                :task="task"
                :controls="true"
                :point-types="pointTypes"
                :floor-image="image"
                :new-floor-image="newImage"
                :points="points"
                :rooms="selectedFloorRooms"
                :protocols="filteredProtocols"
                :premises="filteredPremises"
                :plan-editor-mode="planEditorMode"
                :defect-classes="defectClasses"
                :show-comments="showComments"
                :has-comments="hasComments"
                class="content_body"
                :should-display-sight="true"

                :filter="filter"
                :configuration="configuration"
                :default-configuration="defaultConfiguration"

                :house-id="houseId"

                :floor-id="floorId"
                :floor-plans="floorPlans"

                :plan-id="planId"
                :plan-type="planType"
                :plan-layers="planLayers"

                :selected-layer="selectedWorkLayer"
                :polygons-by-layers="polygonsByWorkLayers"
                :work-plan-units="workPlanUnits"
                :polygon-editable="shouldEditPolygon"

                :unsaved="unsaved"
                :acceptable="acceptable"

                @configuration-change="changeConfiguration"
                @configuration-apply="applyConfiguration"

                @point-change-sight="storePointSight"
                @point-change-link="storePointLink"

                @polygon-create="createPolygon"
                @polygon-change="changePolygon"
                @polygon-change-multiple="changePolygonMultiple"
                @polygon-remove="removePolygon"
                @task-select="viewTask"

                @layer-confirm="confirmLayer"
                @layer-cancel="cancelLayer"

                @work-plan-compare="compareWorkPlan"

                @filter-change="changeFilter"
                @filter-confirm="confirmFilter"

                @back="goBack"

                @save="save"
                @point-monitor-select="goToMonitoringComplex"

                @ready="onPlanReady"
                @error="onImageError"
                @load="stop"
                @point:options="optionsPlanPoints"
                @room:double-click="handleRoomDoubleClick"
                @protocol:init="handleProtocolInit"
                @protocol:select="handleProtocolSelect"
                @protocol:save="updateProtocols"
                @room:delete="handleDeleteRoom"
                @markup:linked="handleLinkedMarkup"
                @toggle-defect-classes-display="toggleDefectClassesDisplay"
                @premises:init="onPremisesInit"
                @show-plan-comments="showPlanComments"
                @close-defect-classes="closeDefectClasses"
                @on-send-plan-delta="updateDelta"
                @on-show-plan-comment-form="handleCreatePlanComment"
                @on-set-orientation="handleOnSetOrientation"
                @on-defining-point-dblclick="sendDefiningPointActivity"
                @show-premises-form="onShowPremisesForm"
                @close-premises-form="onClose"
                @show-premises-info="onShowPremisesInfo" />

            <!-- Comparison side left -->
            <div v-if="shouldDisplayComparison && comparisonFrom"
                 class="rel">
                <!-- Viewer -->
                <floor-map ref="comparisonFrom"
                           key="comparison-left"
                           :task="task"
                           :floor-id="floorId"
                           :plan-id="comparisonFrom.planId"
                           :plan-type="comparisonFrom.planType" 
                           :plan-layers="comparisonFrom.layers"
                           :selected-layer="comparisonFrom.selectedLayer"
                           :polygons-by-layers="polygonsByWorkLayers"
                           :work-plan-units="workPlanUnits"

                           :comparable="comparisonFrom.comparable"

                           @filter-confirm="x => confirmFilter(x, { layer: comparisonFrom.selectedLayer })"

                           @back="goBack"
                           @view-move="x => applyComparisonViewing('from', x)"
                           @view-zoom="x => applyComparisonViewing('from', x)" />

                <!-- Version -->
                <select-field v-if="comparisonFrom.selectedVersion"
                              class="abs t-1 l-4 depth-10 w-15 as-select-field-dark"
                              :value="comparisonFrom.selectedVersion"
                              :options="comparisonVersionsAsOptions"
                              value-key="id"
                              label-key="_label"
                              independent
                              @change="x => changeComparisonVersion('from', x)" />

                <!-- About comparison -->
                <div class="f abs t-1 r-1 depth-10 space-x-0.5">
                    <!-- Toggle synced -->
                    <plan-button 
                        :active="comparisonSynced"
                        icon="compare"
                        tooltip="Синхронизировать перемещение"
                        @click="toggleSyncedComparison" />

                    <!-- Reset synced -->
                    <plan-button 
                        icon="fullscreen"
                        tooltip="Сбросить перемещение"
                        @click="resetSyncedComparison" />
                </div>
            </div>

            <!-- Comparison side right -->
            <div v-if="shouldDisplayComparison && comparisonTo"
                 class="rel">
                <!-- Viewer -->
                <floor-map  
                    ref="comparisonTo"
                    key="comparison-right"
                    :task="task"
                    :floor-id="floorId"
                    :plan-id="comparisonTo.planId"
                    :plan-type="comparisonTo.planType"
                    :plan-layers="comparisonTo.layers"
                    :selected-layer="comparisonTo.selectedLayer"
                    :polygons-by-layers="polygonsByWorkLayers"
                    :work-plan-units="workPlanUnits"

                    :floor-image="comparisonTo.planImage"
                    :points="comparisonTo.points"

                    :comparable="comparisonTo.comparable"

                    without-control
                    :without-filter="!comparisonTo.withFilter"
                    without-configurator

                    @filter-confirm="x => confirmFilter(x, { layer: comparisonTo.selectedLayer })"

                    @back="goBack"
                    @view-move="x => applyComparisonViewing('to', x)"
                    @view-zoom="x => applyComparisonViewing('to', x)" />

                <!-- Version -->
                <select-field v-if="comparisonTo.selectedVersion"
                              class="abs t-1 l-1 depth-10 w-15 as-select-field-dark"
                              :value="comparisonTo.selectedVersion"
                              :options="comparisonVersionsAsOptions"
                              value-key="id"
                              label-key="_label"
                              independent
                              @change="x => changeComparisonVersion('to', x)" />

                <!-- Plan -->
                <select-field v-if="comparisonTo.selectedPlan" 
                              class="abs t-1 l-1 depth-10 w-15 as-select-field-dark"
                              :value="comparisonTo.selectedPlan"
                              :options="comparisonPlansAsOptions"
                              value-key="id"
                              label-key="_label"
                              independent
                              @change="compareWorkPlan" />
            </div>

            <!-- Эй я здесь упаду, а ты там выше подвинься -->
            <plan-layer-viewer 
                v-if="shouldDisplayViewer && shouldDisplayLayerViewer && !shouldDisplayComparison"
                :class="classes.planLayerViewer"
                :layers="planLayers"
                :selected-layer="selectedWorkLayer"
                :selected-polygons="selectedWorkPolygons"
                :polygons-by-layers="polygonsByWorkLayers"
                :polygons-by-layers-loading="polygonsByWorkLayersLoading"
                :units="workPlanUnits"
                :animated="configuration.plan_animation_enabled"
                :opened="planLayerViewerOpened"
                :acceptance="acceptance"
                :acceptable="acceptable"
                @toggle="togglePlanLayerViewer"
                @layer-upgrade="upgradeLayer"
                @layer-compare="compareLayer"
                @layer-remove="removeLayer"
                @polygon-open="selectPolygon" />
        </div>

        <!-- Task viewer -->
        <task-viewer 
            :task="viewedTask"
            :modal="false"
            @close="clearViewedTask" />

        <!-- Children -->
        <router-view :selected-point="selectedPoint"
                     @close="onClose" />
    </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import { actionable, resourceable } from '@/store/connectors'

import * as projectStructureUtils from '@/utils/project-structure'
import {VIEW_MODES} from '@/utils/plan'
import { labelAt } from '@/utils/date'
import { then } from '@/utils/immutable'

import { premises } from '@/values/mock'
import { definePlanFilter } from '@/values/filters'

import { planTypes, getPlanLabel, isWorkLayerConfirmed, toFilterForServer } from '@/models/plans'
import { getMonitoringComplexId } from '@/models/points'
import { hasWorkerOrganizationBy, isFinished } from '@/models/tasks'

import FloorMap from '@/components/map/FloorMap';
import DefectClasses from '@/components/map/DefectClasses';
import PremisesClasses from '@/components/map/PremisesClasses';
import CommentsList from '@/components/shared/CommentsList';
import PlanLayerViewer from '@/components/map/PlanLayerViewer'
import SelectField from '@/components/fields/SelectField'
import PlanButton from '@/components/map/PlanButton'
import TaskViewer from '@/components/tasks/TaskViewer'

export default {
  name: 'PlanEditor',
  components: {
    FloorMap,
    DefectClasses,
    CommentsList,
    PremisesClasses,
    PlanLayerViewer,
    SelectField,
    PlanButton,
    TaskViewer
  },
  mixins: [
    resourceable({ on: 'floors', name: 'floorPlans' }),
    resourceable({ on: 'floorPlans', name: 'plan' }),
    resourceable({ on: 'floorPlans', name: 'planComments' }),
    resourceable({ on: 'floorPlans', name: 'planPoints' }),
    resourceable({ on: 'floorPlans', name: 'workPlanUnits', mounted: true }),
    resourceable({ on: 'floorPlans', name: 'task' }),

    actionable({ on: 'points', name: 'applyPlanPoints', loadable: true }),
    actionable({ on: 'points', name: 'applyRoomPoints', loadable: true }),
    actionable({ on: 'points', name: 'storePointSight' }),
    actionable({ on: 'points', name: 'storePointLink' }),

    actionable({ on: 'floorPlans', name: 'updatePlanDelta', loadable: true }),

    actionable({ on: 'floorPlans', name: 'addWorkPolygon' }),
    actionable({ on: 'floorPlans', name: 'updateWorkPolygon' }),
    actionable({ on: 'floorPlans', name: 'updateWorkPolygons' }),
    actionable({ on: 'floorPlans', name: 'removeWorkPolygon' }),
    actionable({ on: 'floorPlans', name: 'createWorkPolygons', loadable: true }),
    actionable({ on: 'floorPlans', name: 'confirmWorkLayer', loadable: true }),
    actionable({ on: 'floorPlans', name: 'cancelWorkLayer', loadable: true }),
    actionable({ on: 'floorPlans', name: 'removeWorkLayer', loadable: true }),
    actionable({ on: 'floorPlans', name: 'compareWorkLayers', loadable: true }),

    actionable({ on: 'floorPlans', name: 'fetchWorkLayerVersions', loadable: true }),
    actionable({ on: 'floorPlans', name: 'fetchWorkLayerPolygons', loadable: true })
  ],
  props: {
    planId: { type: [String, Number], default: null },

    projectId: { type: [String, Number], default: null },
    roomId: { type: [String, Number], default: null }
  },
  data() {
    const { acceptance, 'filter[mailing_id]': mailingId } = this.$route.query
    const planType = this.$route.params.planType || this.$route.meta.planType || planTypes.Common

    const configuration = {
      tile_optimization_enabled: process.env.VUE_APP_PLAN_OPT === 'true' && get('tile_optimization_enabled', 'true', { cookie: true }) === 'true',
      plan_animation_enabled: get('plan_animation_enabled', 'true', { cookie: true }) === 'true'
    }

    return {
      configuration,
      defaultConfiguration: configuration,

      comparisonVersions: [],

      comparisonFrom: null,
      comparisonTo: null,

      comparisonSynced: true,

      planLayerViewerOpened: get('plan-layer-viewer-opened', 'true', { cookie: true }) === 'true',

      image: null,
      points: [],

      viewedTask: null,

      filter: definePlanFilter({
        planType,
        acceptance,
        mailingId
      }),

      loading: false,
      newImage: null,
      protocols: [],
      rooms: [],
      premisesMock: premises,
      defectClasses: false,
      protocolFilterId: '',
      protocolFilter:{
        buildingStandardId :null,
        workTypeId :null,
        protocolId :null
      },
      showComments: false,
      currentPremisesClass: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationId']),
    ...mapGetters('account', ['hasAccess']),
    ...mapGetters('floorPlans', [
      'plan', 
      'room', 
      'selectedWorkLayer', 
      'polygonsByWorkLayers', 
      'polygonsByWorkLayersLoading', 
      'selectedWorkLayerUnsavedPolygons'
    ]),
    ...mapGetters('viewer/plan', ['selectedPoint', 'selectedWorkPolygons']),

    ...mapState('project', ['selectedPlan']),
    ...mapState('dirs', ['pointTypes', 'workTypes', 'buildingStandardTypes']),
    ...mapState('floorPlans', ['planComments', 'floorPlanImage']),
    ...mapState('tasks', {
      protocolTask: state => state.task
    }),
    ...mapGetters({selectedFloorRooms: 'floors/selectedFloorRooms'}),
    ...mapGetters('floorPlans', ['buildingStandardTree']),
    ...mapGetters('auth', ['hasPermissions']),
    ...mapGetters('rooms', ['roomTypes']),
    ...mapGetters('viewer/plan', ['premisesClassesVisibility']),

    classes() {
      return {
        container: {
          'wh-full': true,
          'cols-minmax-min': this.shouldDisplayViewer && !this.shouldDisplayComparison,
          'cols-2 gap-0.5 bg-black': this.shouldDisplayComparison
        },
        planLayerViewer: {
          'h-full bg-rock': true,
          'w-18': this.planLayerViewerOpened,
          'w-3': !this.planLayerViewerOpened
        }
      }
    },

    commonLoading() {
      return this.loading 
        || this.applyPlanPointsLoading 
        || this.applyRoomPointsLoading 
        || this.confirmWorkLayerLoading
        || this.cancelWorkLayerLoading
        || this.removeWorkLayerLoading
        || this.compareWorkLayersLoading
        || this.fetchWorkLayerVersionsLoading
        || this.createWorkPolygonsLoading
        || this.updatePlanDeltaLoading
    },

    planType() {
      return this.$route.params.planType || this.$route.meta.planType || planTypes.Common
    },

    planLayers() {
      return this.plan?.layers || []
    },

    houseId() {
      return this.plan?.floor?.house_id
    },

    floorId() {
      return this.plan?.floor_id
    },

    shouldDisplayViewer() {
      return this.image || !!this.planLayers.length
    },

    shouldDisplayLayerViewer() {
      return this.planType === planTypes.Work
    },

    shouldDisplayComparison() {
      return !!(this.comparisonFrom || this.comparisonTo)
    },

    shouldEditPolygon() {
      return isWorkLayerConfirmed(this.selectedWorkLayer)
    },

    comparisonVersionsAsOptions() {
      return this.comparisonVersions.map((x, i) => ({
        ...x,
        _label: `${i + 1}. Версия от ${labelAt(x.created_at, { iso: true, withAlias: false, withTime: false })}`
      }))
    },

    comparisonPlansAsOptions() {
      return this.floorPlans.map(x => ({
        ...x,
        _label: getPlanLabel(x)
      }))
    },

    unsaved() {
      return !!this.selectedWorkLayerUnsavedPolygons.length
    },

    acceptance() {
      const { acceptance } = this.$route.query

      return acceptance
    },

    acceptable() {
      return this.task && (isFinished(this.task) || hasWorkerOrganizationBy(this.task, this.organizationId))
    },

    projectFilter() {
      return this.$store.getters['project/projectFilter']('plan');
    },
    selectedFilters() {
      return this.$store.getters['photos/selectedFilters']('projectPhotos');
    },
    filteredProtocols() {
      let protocols = [...this.protocols];
      if (this.protocolFilter.protocolId){
        return protocols.filter(protocol => protocol.id === this.protocolFilter.protocolId)
      }
      else if(this.protocolFilter.workTypeId){
        return protocols.filter(protocol => protocol.data.work_type_id === this.protocolFilter.workTypeId);
      }
      else if(this.protocolFilter.buildingStandardId){
        return protocols.filter(protocol => protocol.data.building_standard_id === this.protocolFilter.buildingStandardId)
      }
      else {
        return protocols
      }
    },
    filteredPremises() {
      return this.currentPremisesClass
        ? this.premisesMock.filter(({type}) => type === this.currentPremisesClass)
        : this.premisesMock
    },
    planEditorMode() {
      if (this.roomId) {
        return 'room';
      } else {
        return 'plan';
      }
    },
    hasComments() {
      return this.planComments.length > 0;
    }
  },
  watch: {
    $route(value) {
      const { params } = value
      if (params && params.formShowed) {
        return
      }

      //TODO Проверить, почему без форсапдейта не всегда загружаются точки
      // Это очень плохо, очень. Это еще хуже на каждом изменении роута
      this.$forceUpdate();
      this.init();
    },

    acceptable(x) {
      this.filter.withAcceptable = x
    },

    configuration(x) {
      Object.entries(x).forEach(([k, v]) => set(k, v, { cookie: true }))
    },

    plan(x) {
      const type = this.planType

      this.clearPlanData()

      this.setSelectedPlan(x)

      type === planTypes.Work && this.fetchFloorPlans({ 
        floorId: x.floor_id, 
        withImage: true,
        withPoints: true,
        withPointsImage: true
      }).then(floorPlans => then(this.$route.query['compare-with'], x => {
        const from = this.plan
        const to = floorPlans.find(y => y.id === x)

        this.comparisonFrom = from && {
          selectedLayer: from.layers[0],
          layers: from.layers,
          planId: this.planId,
          planType: this.planType
        }

        this.comparisonTo = to && {
          selectedPlan: to,
          planId: to.id,
          planType: to.type,
          planImage: to.plan,
          points: to.defining_points,

          withFilter: true,
          comparable: true      
        }

        from && to && this.$emit('expanded')
      }))

      this.sentUserActivity({
        slug: ({
          [type === planTypes.Common || type === planTypes.Tech]: 'floor_plan',
          [type === planTypes.Work]: 'floor_work_plan'
        })[true],
        type: 'opened',
        subject_id: this.$route.params.planId
      });
    },

    room(value) {
      this.image = value.plan_image
    },

    floorPlanImage: {
      deep: true,
      handler(val) {
        this.newImage = val
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('floorPlans', ['fetchRoom']),

    ...mapActions('dialogs/confirmation', ['confirm']),
    ...mapActions('floors', ['getFloorRooms']),
    ...mapActions('dirs', ['getPointTypes', 'getWorkTypes', 'getBuildingStandardTypes']),
    ...mapActions('project', ['getProjectStructure', 'setSelectedPlan']),
    ...mapActions('navigator', {
      updateNavBar: 'update',
      clearNavBar: 'clear'
    }),
    ...mapActions('rooms', ['showRoom',
                            'updateRoom',
                            'getRoomPoints',
                            'storeRoomDefiningPoints',
                            'destroyRoomDefiningPoints',
                            'dirsRoomTypes']),
    ...mapMutations('loader', {
      start: 'START_LOADING',
      stop: 'STOP_LOADING'
    }),
    ...mapMutations('form', {showForm: 'SHOW_FORM'}),
    ...mapActions('tasks', ['showTask', 'updateTask', 'multipleDestroyTask']),
    ...mapActions('form', ['closeForm']),
    ...mapActions('viewer/plan', ['toggleViewMode','addViewMode', 'setPremisesClassesVisibility']),
    ...mapActions('floorPlans', ['getFloorPlan', 'getFloorPlanPoints', 'getFloorPlanProtocols', 'fetchDelta', 'getPlanComments', 'updateOrientation']),
    ...mapActions('comments', ['storeComment', 'updateComment', 'removeComment']),
    ...mapActions('activity', ['sentUserActivity']),

    fetch() {
      return this.fetchPlan({ 
        id: this.planId, 
        type: this.planType, 
        withFloor: true,
        withImage: true, 
        withPoints: true, 
        withLayers: true 
      })
        .then(({ plan: image, points }) => {
          this.points = points
          this.image = image
        })
    },

    changeFilter(x) {
      this.filter = x
    },

    confirmFilter(x, { layer } = {}) {
      this.filter = x

      this.planType === planTypes.Work 
        && this.fetchWorkLayerPolygons({ 
          acceptance: this.acceptance,
          layer: layer || this.selectedWorkLayer, 
          filter: toFilterForServer(x, { acceptance: this.acceptance }), 
          withWorkTypes: true 
        })
    },

    changeConfiguration(x) {
      this.configuration = x
    },

    applyConfiguration(x) {
      this.configuration = x
    },

    onPlanReady() {
      if (this.selectedPlan.type === 'technical' && !this.$route.params.roomId) {
        this.getFloorRoomsData().then(rooms => this.rooms = rooms)
      }
    },

    updateDelta(value) {
      this.updatePlanDelta({
        plan: {
          id: this.planId,
          type: this.planType
        },
        layer: this.selectedWorkLayer,
        value
      })
        .then(dialogs.success.bind(this))
        .catch(dialogs.failed.bind(this))
    },

    upgradeLayer(layer) {
      this.showForm({
        formName: 'plan-form',
        callback: this.fetch.bind(this),
        payload: {
          id: layer.id,
          as: 'work-layer'
        }
      }); 
    },

    compareLayer(layer) {
      const compare = ({ versions }) => {
        this.comparisonVersions = [layer, ...versions]

        this.comparisonFrom = {
          selectedLayer: this.comparisonVersions[0],
          selectedVersion: this.comparisonVersions[0],
          layers: [this.comparisonVersions[0]],
          planId: this.planId,
          planType: this.planType,

          comparable: true
        }

        this.comparisonTo = {
          selectedLayer: this.comparisonVersions[1],
          selectedVersion: this.comparisonVersions[1],
          layers: [this.comparisonVersions[1]],
          planId: this.planId,
          planType: this.planType,

          withFilter: true,

          comparable: true
        }

        this.compareWorkLayers({ layers: [this.comparisonFrom.selectedLayer, this.comparisonTo.selectedLayer] })
        this.fetchWorkLayerPolygons({ layer: this.comparisonTo.selectedLayer, replaceable: false })

        this.$emit('expanded')
      }

      const notCompare = () => {
        dialogs.warning.call(this, { message: 'Отсутствуют версии для сравнения' })
      }

      this.fetchWorkLayerVersions({ layer })
        .then(versions => versions.length ? compare({ versions }) : notCompare())
    },

    compareWorkPlan(plan) {
      const compare = ({ to }) => this.$router.push({ 
        query: {
          'compare-with': to.id
        } 
      }) 

      this.plan && plan && compare({ to: plan })
    },

    changeComparisonVersion(side, version) {
      const define = ({ by, x }) => ({ by, x: by === side ? version : x })

      const swap = ({ by, x }) => ({ by, x: by !== side && version.id === x.id ? [
        this.comparisonFrom.selectedLayer, 
        this.comparisonTo.selectedLayer
      ][+(by === 'from')] : x })

      const [from, to] = [
        compose(swap, define)({ by: 'from', x: this.comparisonFrom.selectedLayer }).x,
        compose(swap, define)({ by: 'to', x: this.comparisonTo.selectedLayer }).x
      ]

      this.comparisonFrom.selectedLayer = from
      this.comparisonFrom.selectedVersion = from
      this.comparisonFrom.layers = [from]

      this.comparisonTo.selectedLayer = to
      this.comparisonTo.selectedVersion = to
      this.comparisonTo.layers = [to]

      this.compareWorkLayers({ layers: [this.comparisonFrom.selectedLayer, this.comparisonTo.selectedLayer] })
      this.fetchWorkLayerPolygons({ layer: this.comparisonTo.selectedLayer })
    },

    applyComparisonViewing(side, { position, positionDelta, zoom }) {
      const synced = this.comparisonSynced

      position && side === 'from' && synced && this.$refs.comparisonTo.adjustViewCenter({ delta: positionDelta })
      position && side === 'to' && synced &&this.$refs.comparisonFrom.adjustViewCenter({ delta: positionDelta })

      zoom && side === 'from' && synced && this.$refs.comparisonTo.setViewZoom({ zoom })
      zoom && side === 'to' && synced && this.$refs.comparisonFrom.setViewZoom({ zoom })
    },

    toggleSyncedComparison() {
      this.comparisonSynced = !this.comparisonSynced
    },

    resetSyncedComparison() {
      this.comparisonSynced = true
      this.$refs.comparisonFrom.resetView()
      this.$refs.comparisonTo.resetView()
    },

    confirmLayer(layer) {
      this.confirmWorkLayer({ layer }).then(this.fetch.bind(this))
    },

    cancelLayer(layer) {
      dialogs.confirm.call(this, { message: 'Отменить изменение слоя? Загруженные данные будут потеряны, а слой вернется к предыдущей версии' })
        .then(() => this.cancelWorkLayer({ layer }))
        .then(this.fetch.bind(this))
        .catch(() => {})
    },

    removeLayer(layer) {
      dialogs.confirmDeletion.call(this, { subject: 'текущую версию слоя' })
        .then(() => this.removeWorkLayer({ layer }))
        .then(this.fetch.bind(this))
        .catch(() => {})
    },

    selectPolygon(polygon) {
      this.$refs.viewer?.selectWorkPolygon?.(polygon)
    },

    createPolygon(polygon) {
      this.addWorkPolygon({ layer: this.selectedWorkLayer, polygon })
    },

    changePolygon(polygon) {
      this.updateWorkPolygon({ polygon, layer: this.selectedWorkLayer })
        .then(dialogs.saved.bind(this))
    },

    changePolygonMultiple({ polygons }) {
      this.updateWorkPolygons({ polygons, layer: this.selectedWorkLayer })
        .then(dialogs.saved.bind(this))
    },

    removePolygon(polygon) {
      dialogs.confirmDeletion.call(this, { subject: 'полигон' })
        .then(() => this.removeWorkPolygon({ polygon }))
        .then(() => dialogs.deleted.call(this, { message: 'Полигон удален' }))
    },

    togglePlanLayerViewer(x) {
      set('plan-layer-viewer-opened', this.planLayerViewerOpened = x, { cookie: true })
    },

    viewTask(x) {
      this.viewedTask = x
    },

    clearViewedTask() {
      this.viewedTask = null
    },

    goToMonitoringComplex(point) {
      this.$router.push({
        name: 'project.plan.monitoring',
        params: {
          complexId: getMonitoringComplexId(point),
          formShowed: true
        }
      })
      this.$emit('expanded')
    },

    goBack() {
      const { id } = this.$route.params

      const go = ({
        [!!this.acceptance]: () => this.$router.push({ name: 'project.task', params: { taskId: this.acceptance }}),
        [this.shouldDisplayComparison]: () => {
          this.comparisonTo = null
          this.comparisonFrom = null
          this.$router.push({ query: {} })
        } 
      })[true] || (() => this.$router.push({ name: 'project', params: { id } }))

      go()

      this.$emit('back')
    },

    async handleProtocolInit() {
      if (!this.workTypes.length) {
        this.getWorkTypes()
      }
      try {
        let response =  await this.getFloorPlanProtocols({floorPlanId: this.planId});
        this.protocols = response.data || [];
      } finally {
        this.loading = false;
      }
    },
    async handleProtocolSelect(payload) {
      const callback = () => {
        this.updateProtocols(this.planId);
      }
      await this.closeForm()
      this.showForm({
        formName: 'protocol-form',
        formTitle: 'Редактирование протокола',
        formType: 'update',
        action: this.updateTask,
        removeAction: this.multipleDestroyTask,
        callback: callback,
        payload: payload
      })
    },
    handleLinkedMarkup() {
      this.getFloorRoomsData();
    },
    async handleDeleteRoom(roomId) {
      const callback = () => {
        this.loading = true;
        this.updateRoom({
          id: roomId,
          payload: {boundary: []}
        })
          .then(() => {
            this.getFloorRoomsData();
            this.$message({
              type: 'success',
              message: 'Разметка успешно удалена'
            });
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'При удалении произошла ошибка'
            });
          })
          .finally(() => {
            this.loading = false;
          });
      };
      this.confirm({
        message: 'Вы уверены, что хотите удалить разметку пользователя?',
        callback
      });
    },

    async handleRoomDoubleClick(roomId) {
      this.$router.push({
        name: 'project.plan.rooms',
        params: {roomId: roomId}
      });

    },
    async init() {
      if (!this.pointTypes.length) {
        await this.getPointTypes();
      }
      if (!this.buildingStandardTypes.length) {
        this.getBuildingStandardTypes()
      }
      this.updateNavBar({
        ui_go_back: true,
        bar_photo: null,
        bar_classes: true
      });

      try {
        let type

        type = this.planType

        const mailingId = this.$route.query['filter[mailing_id]']

        this.filter = definePlanFilter({
          planType: type,
          acceptance: this.acceptance,
          mailingId
        })

        const plan = this.planId && await this.fetchPlan({ 
          id: this.planId, 
          type,
          acceptance: this.acceptance,
          withFloor: true,
          withPoints: true, 
          withLayers: true,
          polygonsFilter: toFilterForServer(this.filter, { mailingId })
        })

        const room = this.roomId && await this.fetchRoom({ id: this.roomId })

        type = room?.type || plan?.type || this.planType

        is(type === planTypes.Common || type === planTypes.Tech) && (this.image = plan.plan)
        is(type === planTypes.Common || type === planTypes.Tech) && (this.points = plan.points)
        is(type === planTypes.Common) && this.addViewMode(VIEW_MODES.POINT)

        is(type === planTypes.Tech) && this.addViewMode(VIEW_MODES.ROOM)
        is(type === planTypes.Tech) && this.getFloorRoomsData().then(rooms => this.rooms = rooms) 

        if (this.hasAccess({ permissions: 'project_comments_show' })) {
          this.fetchPlanComments({ id: this.planId, type: this.planType })
        }

        if (this.$route.query.protocolId) {
          await this.showTask({taskId: this.$route.query.protocolId});
          this.protocols = [this.task];
          this.handleProtocolSelect({protocolId: this.task.id});
        }

        projectStructureUtils.iterateCountAndSetPercentOfValues(this.points, 'changes_percent', 'changes_count');
        projectStructureUtils.iterateCountAndSetPercentOfValues(this.points, 'actual_recognized_objects_percent', 'actual_recognized_objects_count');

        is(type === planTypes.Work) && this.$socket.echoClient.private(`v1.floor-work-plans.${this.planId}`).listen('.layers.updated', () => {
          this.fetch().then(() => dialogs.success.call(this, { message: 'Слои рабочего плана обновлены' }))
        })
      } catch(e) {
        console.error(e)
      }
    },
    clearPlanData(){
      this.points = []
      this.rooms=[]
      this.protocols=[]
      this.image = null
      this.newImage = null
      this.comparisonFrom = null
      this.comparisonTo = null
    },
    async updateProtocols() {
      try {
        this.loading = true;
        let {data} = await this.getFloorPlanProtocols({floorPlanId: this.planId})
        this.protocols = data;
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'Не удалось обновить список протоколов'
        })
      } finally {
        this.loading = false;
      }
    },
    async getFloorRoomsData(filter) {
      try {
        this.loading = true;
        let {data} = await this.getFloorRooms({
          floorId: this.floorId,
          payload: {params: {filter: filter}}
        });
        return data
      } finally {
        this.loading = false;
      }
    },
    onImageError() {
      this.$notify({
        type: 'error',
        title: 'Не удалось загрузить план этажа'
      });
    },

    save({ pointsForCreate, pointsForDelete, pointsForUpdate, resolve, reject }) {
      let r

      r ||= is(this.planType === planTypes.Common || this.planType === planTypes.Tech) 
        && this.planEditorMode === 'plan' 
        && (() => this.applyPlanPoints({ 
          plan: { id: this.planId },
          pointsForCreate,
          pointsForDelete,
          pointsForUpdate
        })
          .then(() => {
            this.fetchPlanPoints({ planId: this.planId })
              .then(x => this.points = x)

            this.$notify({
              type: 'success',
              title: 'Выполнено',
              message: 'Реперные точки обновлены'
            })
          }))

      r ||= is(this.planType === planTypes.Common || this.planType === planTypes.Tech) 
        && this.planEditorMode === 'room' 
        && (() => this.applyRoomPoints({
          room: { id: this.roomId },
          pointsForCreate,
          pointsForDelete,
          pointsForUpdate
        })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Выполнено',
              message: 'Реперные точки обновлены'
            })
          }))

      r ||= this.planType === planTypes.Work 
        && (() => this.createWorkPolygons({ layer: this.selectedWorkLayer })
          .then(() => {
            this.fetchWorkLayerPolygons({ 
              acceptance: this.acceptance, 
              layer: this.selectedWorkLayer, 
              filter: toFilterForServer(this.filter, { acceptance: this.acceptance }),
              withWorkTypes: true 
            })
            dialogs.saved.call(this, { message: 'Разметка рабочего слоя обновлена' })
          }))

      return r()
        .then(resolve)
        .catch(reject)
    },
    optionsPlanPoints(point) {
      this.showForm({
        formName: 'plan-item-form',
        callback: this.fetch.bind(this),
        payload: { point_id: point.id }
      })
    },
    toggleDefectClassesDisplay() {
      this.defectClasses = !this.defectClasses
    },
    closeDefectClasses() {
      this.defectClasses = false
      this.protocolFilter.buildingStandardId = null;
      this.protocolFilter.workTypeId = null;
      this.protocolFilter.protocolId = null;
    },
    handleProtocolFilterClick(node) {
      this.protocolFilter.buildingStandardId = null;
      this.protocolFilter.workTypeId = null;
      this.protocolFilter.protocolId = null;
      switch (node.type) {
      case 'buildingStandard':
        this.protocolFilter.buildingStandardId = node.id;
        break
      case 'workType':
        this.protocolFilter.workTypeId = node.id;
        break
      case 'protocol':
        this.protocolFilter.protocolId = node.id;
        break;
      default:
        this.protocolFilter.buildingStandardId = null;
        this.protocolFilter.workTypeId = null;
        this.protocolFilter.protocolId = null;
      }
    },
    showPlanComments() {
      this.showComments = !this.showComments;
    },
    getComments() {
      return this.fetchPlanComments({ id: this.planId, type: this.planType });
    },
    async handleGetPlanComments() {
      await this.getComments();
      this.showComments = true;
    },


    handleCreatePlanComment() {
      const storeCommentAction = commentData => {
        const payload = {
          type: 'floor_plan',
          data: {
            'floor_plan_id': this.planId,
            ...commentData
          }
        };
        return this.storeComment(payload);
      };

      this.showForm({
        formName: 'plan-comment-form',
        formTitle: 'Создание комментария',
        action: storeCommentAction,
        callback: this.handleGetPlanComments
      })
    },

    handleEditPlanComment(comment) {
      const handleUpdateComment = (commentData, commentId) => {
        const data = {
          commentId,
          payload: commentData
        };
        return this.updateComment(data);
      };

      this.showForm({
        formName: 'plan-comment-form',
        formTitle: 'Редактирование комментария',
        action: handleUpdateComment,
        callback: this.handleGetPlanComments,
        payload: comment
      })
    },

    handleOnSetOrientation: async function({ vector }) {
      try {
        this.loading = true
        await this.updateOrientation({ id: this.planId, vector })
        await this.fetch()
        this.$message({ type: 'success', message: 'Направление на север установлено' })
      } catch (e) {
        this.$message({ type: 'success', message: 'При установки направления на север произошла ошибка' })
      } finally {
        this.loading = false
      }

    },

    onCommentDelete(commentId) {
      this.removeComment({ comment: { id: commentId } })
        .then(async () => {
          await this.getComments();
          this.$message({
            type: 'success',
            message: 'Комментарий удален'
          });
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'При удалении произошла ошибка'
          });
        })
        .finally(() => {
        });
    },
    async onPremisesInit() {
      try {
        this.loading = true
        if (!this.roomTypes.length) {
          await this.dirsRoomTypes()
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    onShowPremisesForm() {
      this.$router.push({
        name: 'project.plan.premises.create',
        params: {
          formShowed: true
        }
      })
    },
    onShowPremisesInfo() {
      this.$router.push({
        name: 'project.plan.premises.info',
        params: {
          formShowed: true
        }
      })
    },
    onClickPremisesClass(type) {
      this.currentPremisesClass = type
    },
    onCancelPremisesClass() {
      this.currentPremisesClass = ''
      this.setPremisesClassesVisibility(false)
    },
    onClose() {
      this.$router.push({
        name: 'project.plan',
        params: {
          planId: this.planId,
          formShowed: true
        }
      });
    },
    sendDefiningPointActivity(subject_id) {
      this.sentUserActivity({
        slug: 'defining_point',
        type: 'opened',
        subject_id
      })
    }
  }
};
</script>
